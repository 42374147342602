import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs-compat";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class FetchDataService {
    // uploadLogo(formData: FormData) {
    //   throw new Error('Method not implemented.');
    // }
    baseUrl = environment.baseUrl;
    token: any;
 
    constructor(private http: HttpClient) {}

    login(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/login`, data);
    }

    registerEmail(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/emailverify`, data);
    }
    verifyEmail(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/validatelink`, data);
    }
    track(userTrackPayload): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/usertrack`,userTrackPayload);
    }
    
    signup(register): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/signup`, register);
    }
    forgetPassword(email): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/forgotpassword`, email);
    }
    resetPassword(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/resetpassword`, data);
    }
    changePassword(data): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/change-password`, data);
    }

    getCountryCodes() {
        return this.http.get(`${this.baseUrl}/api/getCountries`);
    }
    getTotalUnreadMsgs(userId){
        return this.http.get(`${this.baseUrl}/api/totaluread?receiver_id=${userId}`);
    }
    getProfileList(uid,token):Observable<any>{
        return this.http.get(`${this.baseUrl}/api/getprofile?id=${uid}&token=${token}`);
    }
    uploadBanner(file: File, uid: string, email: string): Observable<any> {
        const formData = new FormData();
        formData.append('banner', file);
        formData.append('uid', uid);
        formData.append('email', email);
        return this.http.post(`${this.baseUrl}/api/uploadbanner`, formData);
      }
    
      uploadLogo(file: File, uid: string, email: string): Observable<any> {
        const formData = new FormData();
        formData.append('logo', file);
        formData.append('uid', uid);
        formData.append('email', email);
        return this.http.post(`${this.baseUrl}/api/uploadlogo`, formData);
      }
    
      updateAboutText(data: { about: string, uid: string, email: string }): Observable<any> {
        return this.http.post(`${this.baseUrl}/api/uploadabout`, data);
      }
     
      getUserProfileData(uid: string, token: string): Observable<any> {
        return this.http.get(`https://adminstaging.connekkt.media/api/getProfile?uid=${uid}&token=${token}`);
      }
      getProfileData(id: string) {
        return this.http.get(`${this.baseUrl}/api/profile?id=${id}`);
      }
      profileSellerList(uid: string) {

          return this.http.get(`${this.baseUrl}/api/contentlist?uid=${uid}`);
    }
    profileTrailerList(uid: string) {

          return this.http.get(`${this.baseUrl}/api/contentlist?uid=${uid}`);
    }
    profileSellerListPost(payload: any) {
        return this.http.post(`${this.baseUrl}/api/addprofile_recent`, payload);
      }
      
    //   profileTrailerList(payload: any) {
    //     return this.http.post(`${this.baseUrl}/api/profile_promolist`, payload);
    //   }
      profileTrailerListPost(payload: any) {
        return this.http.post(`${this.baseUrl}/api/addprofile_promo`, payload);
      }
      getProfileSlider(uid: string) {
        return this.http.get(`${this.baseUrl}/api/profile_recentlist?uid=${uid}`);

      }
      getProfileSliderTrailer(uid: string) {
        return this.http.get(`${this.baseUrl}/api/profile_promolist?uid=${uid}`);

      }
      profileRecentDelete(uid: string,imageId: string) {
        return this.http.get(`${this.baseUrl}/api/delete_profilerecent?uid=${uid}&id=${imageId}`);

      }
      // editTrailerProfile(uid:string, promo_index:string, id:string) {
      //   return this.http.post(`${this.baseUrl}/api/editprofile_recent?id=${id}&promo_index=${promo_index}&uid=${uid}`);
      // }
      // editTrailerProfile(uid: string, promo_index: string, id: string) {
      //   // Pass null as the body, since you're not sending any data in the request body
      //   return this.http.post(`${this.baseUrl}/api/editprofile_recent?id=${id}&promo_index=${promo_index}&uid=${uid}`, null);
      // }
      editTrailerProfile(requestData: any) {
        return this.http.post(`${this.baseUrl}/api/editprofile_promo`, requestData);
      }
      // updateSliderImage(requestData: any) {
      //   return this.http.post(`${this.baseUrl}/api/editprofile_promo`, requestData);
      // }
      updateSliderImage(requestData: any) {
        return this.http.post(`${this.baseUrl}/api/editprofile_recent`, requestData);
      }
      // deleteSliderImage(requestData: any) {
      //   return this.http.get(`${this.baseUrl}/api/delete_profilerecent`, requestData);
      // }
      deleteSliderImage(requestData: any) {
        const params = new HttpParams()
          .set('uid', requestData.uid)
          .set('id', requestData.id);
      
        return this.http.get(`${this.baseUrl}/api/delete_profilerecent`, { params });
      }
      
      
      updateSliderTrailer(requestData: any) {
        return this.http.post(`${this.baseUrl}/api/editprofile_promo`, requestData);
      }
      // deleteSliderTrailer(requestData: any) {
      //   return this.http.get(`${this.baseUrl}/api/delete_profilepromo`, requestData);
      // }
      deleteSliderTrailer(requestData: any) {
        const params = new HttpParams()
          .set('uid', requestData.uid)
          .set('id', requestData.id);
      
        return this.http.get(`${this.baseUrl}/api/delete_profilepromo`, { params });
      }
      
      
    }
